<template>
	<main id="container" class="page-container">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="상생할인전" />
				</div>
			</div>
		</div>
		<div id="exhibition" class="page-content">
			<div class="container">
				<a href="https://pf.kakao.com/_XsFds/friend" target="_blank" class="exhibit-banner">
					<div class="exhibit-banner-left">
						<div class="exhibit-banner-img">
							<img src="@/assets/img/exhibition/icon_noticebell.png" alt="배너 아이콘" />
						</div>
						<div class="exhibit-banner-desc">
							<span>동행축제 채널과 <span class="clr-yellow">카카오톡 친구 맺고</span></span>
							<span class="bold-txt">관련 소식을 가장 빠르게 받아보세요</span>
						</div>
					</div>
					<div class="exhibit-banner-button hidden-mobile">
						<span class="banner-text">클릭하고 친구 추가</span>
						<i class="banner-icon"><icon name="icon-arrow-r" /></i>
					</div>
				</a>
				<div class="exhibit-category">
					<ul class="exhibit-category-list">
						<!-- 전체 카테고리를 만들어야함 -->
						<li v-for="(item, idx) in tmpexbClsDcdList" :key="idx" class="exhibit-category-button" @click.prevent="onChangeCategory(item, idx)">
							<div :class="idx == ctgrIndex ? 'exhibit-category-item is-active' : 'exhibit-category-item'">
								<!-- <div class="exhibit-category-item is-active"> -->
								<div class="button-icon">
									<img :src="require(`@/assets/img/exhibition/${setImgIconChange(idx)}`)" :alt="item.dcd" loading="lazy" />
								</div>
								<span class="button-title">{{ item.dcdVal }}</span>
							</div>
						</li>
					</ul>
				</div>
				<div class="exhibit-item-container">
					<ul v-if="items.length > 0" class="exhibit-item-list">
						<li class="exhibit-item-li" v-for="item in items" :key="item.id">
							<exhibition-item-list :item="item" />
						</li>
					</ul>
					<in-ready v-else />
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import PageHeader from '@/components/content/PageHeader';
import ExhibitionItemList from '@/components/content/ExhibitionItemList';
import Icon from '@/components/common/Icon';
import InReady from '@/components/common/InReady';
import { getCheckItems, dateToDateFormat } from '@/assets/js/utils';
import { ACT_GET_TMPEXB_LIST, ACT_GET_COMMON_CODE_LIST } from '@/store/_act_consts';

import { mapGetters } from 'vuex';

export default {
	name: 'Exhibition',
	components: { PageHeader, ExhibitionItemList, Icon, InReady },
	data: () => ({
		campnId: 0,
		ctgrIndex: 0,
		tmpexbClsDcd: 0,
		tmpexbClsDcdList: [],
		ctgrList: [],
		items: [],
		tmpexbItems: [],
	}),
	computed: {
		...mapGetters('common', ['commonCode', 'campn']),
		setImgIconChange() {
			return (idx) => {
				return idx == 0 ? 'icon_check.png' : 'btn_img0' + idx++ + '.png';
			};
		},
		dateConvert() {
			return (item, format) => {
				return dateToDateFormat(new Date(item), format);
			};
		},
		dateString() {
			return (item) => {
				return ('0' + (new Date(item).getMonth() + 1)).slice(-2) + '.' + ('0' + new Date(item).getDate()).slice(-2);
			};
		},
	},
	watch: {},
	async created() {
		this.campnId = this.campn.campnId;
		await this.getCommonCdList();
		this.tmpexbClsDcdList = this.tmpexbClsDcdList.concat(
			{
				dcd: 0,
				dcdVal: '전체',
			},
			this.ctgrList
		);
		this.getTmpexbList();
	},
	mounted() {},
	methods: {
		onChangeCategory(item, idx) {
			if (this.tmpexbClsDcd === item.dcd) return false;
			this.ctgrIndex = idx;
			this.tmpexbClsDcd = item.dcd;
			this.getTmpexbList();
		},
		async getCommonCdList() {
			await this.$store.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, { dcd: '15030', masterYn: 'N' }).then((res) => {
				this.ctgrList = getCheckItems(res);
			});
		},
		getTmpexbList() {
			this.$store.dispatch(`exhibition/${ACT_GET_TMPEXB_LIST}`, { campnId: this.campnId, tmpexbClsDcd: this.tmpexbClsDcd, mainExpsrYn: '' }).then((res) => {
				this.items = getCheckItems(res);
				this.setParam();
			});
		},
		setParam() {
			this.items.forEach((item) => {
				const itemStrtDt = this.dateConvert(item.strtDt, 'yyyyMMdd');
				const itemEndDt = this.dateConvert(item.endDt, 'yyyyMMdd');
				const nowDate = this.dateConvert(new Date(), 'yyyyMMdd');
				item.period = this.dateString(item.strtDt) + ' ~ ' + this.dateString(item.endDt);
				item.closed = itemStrtDt <= nowDate && itemEndDt >= nowDate ? false : true;
			});
			this.items
				.sort((a, b) => a.closed - b.closed)
				.sort((a, b) => {
					if (a.closed && b.closed) return b.strtDt - a.strtDt;
				});
		},
	},
};
</script>
