<template>
	<a :href="item.platformUrl" target="_blank" class="exhibit-item-content" v-bind:class="{ closed: item.closed }">
		<div class="item-content">
			<div class="item-content-info">
				<div class="item-content-info-coupon">
					<div class="item-content-info-coupon-img">
						<img :src="item.tmpexbImgUrl" :alt="item.tmpexbImgNm" />
					</div>
					<div class="item-content-info-coupon-desc">
						<span class="subtext">{{ item.tmpexbPfxCn }}</span>
						<span class="cpndisc">{{ item.tmpexbBodysCn }}</span>
					</div>
				</div>
				<div class="item-content-info-title">{{ item.tmpexbNm }}</div>
				<div class="item-content-info-date">{{ item.period }}</div>
			</div>
			<!-- <div class="item-content-sub">
				<span class="subtitle">{{ item.brand }}</span>
				<span class="subtext">{{ item.desc }}</span>
			</div> -->
		</div>
	</a>
</template>

<script>
export default {
	name: 'ExhibitionItemList',
	components: {},
	props: {
		item: null,
	},
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
};
</script>
